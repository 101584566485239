<template>
  <ModalForm
    v-model="formData"
    title="Создать таблицу"
    class="modal--title-reset"
    text-btn-confirm="Создать"
    btn-size="xl"
    only-btn-submit
    @request="createTable"
    @reject="onReject"
  >
    <VSelect
      label="Тип таблицы"
      class="mb-30"
      name="type"
      :options="tableTypeOptions"
      :allow-empty="false"
    />

    <VSelect
      label="Учет партий"
      class="mb-30"
      name="restriction_warehouse"
      :options="restrictionWarehouseOptions"
      :allow-empty="false"
    />
  </ModalForm>
</template>

<script>
import VSelect from '@/components/Form/VSelect.vue'
import ModalForm from '@/components/ui/Modal/ModalForm.vue'

import { LoadCalendarModel } from '@/entities/load-calendar'
import { prepareFormDataId } from '@/utils/form/prepareFormDataId'
import { showAlertError } from '@/utils/store/alert'
import { TableTimeAndLoadApi } from '@/api/calendar/TableTimeAndLoadApi'

export default {
  name: 'FormCreateTable',
  components: { ModalForm, VSelect },
  props: {
    calendarTabId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    formData: {
      type: LoadCalendarModel.TABLE_TYPES[0],
      restriction_warehouse: LoadCalendarModel.RESTRICTION_WAREHOUSE[0]
    }
  }),

  computed: {
    tableTypeOptions () {
      return LoadCalendarModel.TABLE_TYPES.filter(item => item.id !== 2)
    },
    restrictionWarehouseOptions () {
      return LoadCalendarModel.RESTRICTION_WAREHOUSE
    }
  },

  methods: {
    async createTable (formData) {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')
        const preparedFormData = this.getPrepared(formData)

        const response = await TableTimeAndLoadApi.create(preparedFormData)

        this.$emit('response', response)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    getPrepared (formData) {
      const preparedFormData = prepareFormDataId(formData, ['type'])
      preparedFormData.restriction_warehouse = preparedFormData.restriction_warehouse.value
      preparedFormData.calendar_tab_id = this.calendarTabId

      preparedFormData.table = this.getTableDataBy(preparedFormData.type_id)

      return preparedFormData
    },

    getTableDataBy (typeId) {
      if (typeId === LoadCalendarModel.ETableTypes.currentLoad) {
        return {
          rows: []
        }
      }

      return this.getTimeTableData()
    },

    getTimeTableData () {
      return {
        head: [
          [...LoadCalendarModel.tableHead.map(th => ({ ...th, rowspan: 2 }))]
        ],
        columns: [LoadCalendarModel.rangeCol],
        rows: []
      }
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>

import { getIds } from '@/utils/array'
import { LoadCalendarModel, LoadCalendarLib } from '@/entities/load-calendar'

export function getFormatWaitingForLaunchTable (tableRows) {
  const item = tableRows[0]

  const preparedItem = {
    id: item.id,
    rows: getSimpleRowsWithRange(item.rows)
  }

  return [preparedItem]
}

export function getFormatCurrentLoadTable (tableRows) {
  const preparedTableRows = []

  for (const item of tableRows) {
    const preparedItem = getItemWithWindingMethodIds(item)
    preparedItem.rows.push(...getSimpleRowsWithRange(item.rows))

    preparedTableRows.push(preparedItem)
  }

  return preparedTableRows
}

function getSimpleRowsWithRange (rows) {
  const preparedRows = []

  for (const item of rows) {
    const row = getIdAndRange(item)
    LoadCalendarLib.formatRange(row.range)

    preparedRows.push({
      ...item,
      ...row
    })
  }

  return preparedRows
}

export function getFormatTimeTable (data) {
  const head = []

  if (data.type_id === LoadCalendarModel.ETableTypes.byCoatingType) {
    head.push(formatTimeTableCoatingType(data.head[0]))
  } else {
    head.push(formatTimeTableSpringType(data.head[0]))
  }

  if (data.head[1]) {
    if (data.type_id === LoadCalendarModel.ETableTypes.byOperation) {
      head.push(formatTimeTableOperation(data.head[1]))
    } else {
      head.push(formatTimeTableGOST(data.head[1]))
    }
  }

  return {
    head,
    rows: getFormatTimeTableRows(data)
  }
}

function formatTimeTableSpringType (head) {
  const preparedHead = []

  const l = head.length
  for (let i = 0; i < l; i++) {
    if (i < 3) {
      preparedHead.push(head[i])
      continue
    }

    const th = { ...head[i] }
    th.spring_type_ids = getIds(th.value)
    delete th.value

    preparedHead.push(th)
  }

  return preparedHead
}

function formatTimeTableGOST (head) {
  const preparedHead = []

  const l = head.length
  for (let i = 0; i < l; i++) {
    const th = { ...head[i] }
    th.gost_ids = getIds(th.value)
    delete th.value

    preparedHead.push(th)
  }

  return preparedHead
}

function formatTimeTableOperation (head) {
  const preparedHead = []

  const l = head.length
  for (let i = 0; i < l; i++) {
    const th = { ...head[i] }
    delete th.value

    preparedHead.push(th)
  }

  return preparedHead
}

function formatTimeTableCoatingType (head) {
  const preparedHead = []

  const l = head.length
  for (let i = 0; i < l; i++) {
    if (i < 3) {
      preparedHead.push(head[i])
      continue
    }

    const th = { ...head[i] }
    delete th.value

    preparedHead.push(th)
  }

  return preparedHead
}

function getFormatTimeTableRows (data) {
  const rows = []

  for (const item of data.rows) {
    const preparedItem = getItemWithWindingMethodIds(item)

    for (const row of item.rows) {
      preparedItem.rows.push(formatTimeTableRow(row, data.columns))
    }

    rows.push(preparedItem)
  }

  return rows
}

function getItemWithWindingMethodIds (item) {
  return {
    id: item.id,
    dWires: item.dWires,
    winding_method_ids: getIds(item.windingMethods),
    rows: []
  }
}

function formatTimeTableRow (item, columns) {
  const preparedItem = getIdAndRange(item)

  for (const col of columns) {
    if (col.key === LoadCalendarModel.rangeCol.key) {
      LoadCalendarLib.formatRange(preparedItem[col.key])
      continue
    }

    preparedItem[col.key] = {
      timing_id: item[col.key]?.timing_id || null
    }
  }

  return preparedItem
}

function getIdAndRange (item) {
  return {
    id: item.id,
    range: { ...item.range }
  }
}
